import React from "react"
import Layout from "../components/layout"
import Home from "./home"

export default function Main({ data }) {
  return (
    <Home></Home>
  )
}

